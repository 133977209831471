// https://thednp.github.io/kute.js/textWrite.html
import KUTE from 'kute.js'

(function () {
    const counting = () => {
        const elements = document.querySelectorAll("[data-counterend]")

        for (let i = 0; i < elements.length; i++) {
            const el = elements[i]
            el.innerHTML = el.getAttribute('data-counterstart') || "0";
            let number = Number(el.getAttribute('data-counterend'));

            let numTween = KUTE.to(el, {number}, {duration: 2000, easing: 'easingCubicIn'});

            const observer = new IntersectionObserver(
                ([e]) => {
                    if (e.isIntersecting) {
                        numTween.start();
                        observer.unobserve(e.target)
                    }
                },
                {threshold: [1], rootMargin: "-10% 0px -10% 0px"}
            );

            observer.observe(el);
        }
    }

    document.addEventListener('DOMContentLoaded', counting);
})()